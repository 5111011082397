import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

interface UniversityData {
    Rank: number;
    University: string;
    Latitude: string;
    Longitude: string;
    Info: string;
    "URL-department": string;
    "URL-Application1": string;
    "URL-Course-description": string;
    "URL-Faculty&research": string;
}

const UniversityRank_USnews = () => {
    const [data, setData] = useState<UniversityData[]>([]);
    const [loading, setLoading] = useState(true);

    // データの取得
    useEffect(() => {
        axios
            .get("/university_data.csv")
            .then((response) => {
                const parsedData = parseCSV(response.data);
                setData(parsedData.sort((a, b) => a.Rank - b.Rank)); // ランキング順にソート
                setLoading(false);
            })
            .catch((error) => {
                console.error("データの取得中にエラーが発生しました:", error);
                setLoading(false);
            });
    }, []);

    // CSVをパースする関数
    const parseCSV = (csvString: string): UniversityData[] => {
        const rows = csvString.split("\n");
        const headers = rows[0].split(",");
        return rows.slice(1).map((row) => {
            const values = row.split(",");
            return headers.reduce((acc, header, idx) => {
                // @ts-ignore
                acc[header.trim() as keyof UniversityData] = values[idx]?.trim();
                return acc;
            }, {} as UniversityData);
        });
    };

    if (loading) {
        return <div>データを読み込んでいます...</div>;
    }

    return (
        <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
            <h1>CS学科ランキング</h1>
            <p>ランキング順に大学名、情報、リンクを表示します。</p>
            {data.map((row, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                    {/* 大学名リンク */}
                    <h2>
                        Rank {row.Rank}:{" "}
                        <Link
                            to={`/map/${row.University}`}
                        >
                            {row.University}
                        </Link>
                    </h2>
                    {/* その他の情報 */}
                    <p><strong>Info:</strong> {row.Info}</p>
                    <p>
                        <a href={row["URL-department"]} target="_blank" rel="noopener noreferrer">
                            学部の公式ホームページ
                        </a>
                    </p>
                    <p>
                        <a href={row["URL-Application1"]} target="_blank" rel="noopener noreferrer">
                            学部の公式アドミッション（修士）ページ
                        </a>
                    </p>
                    <p>
                        <a href={row["URL-Course-description"]} target="_blank" rel="noopener noreferrer">
                            学部の公式コース案内ページ
                        </a>
                    </p>
                    <p>
                        <a href={row["URL-Faculty&research"]} target="_blank" rel="noopener noreferrer">
                            研究領域と担当教員のページ
                        </a>
                    </p>
                    <hr />
                </div>
            ))}
        </div>
    );
};

export default UniversityRank_USnews;