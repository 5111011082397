import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";

interface UniversityData {
    University: string;
    Latitude: string;
    Longitude: string;
    Rank: string;
    Location: string;
    Info: string;
    Icon_Path: string;
}

const UniversityMap = () => {
    const { universityName } = useParams<{ universityName: string }>();
    const [data, setData] = useState<UniversityData[]>([]); // 明示的に型を指定
    const [selectedUniversity, setSelectedUniversity] = useState<UniversityData | null>(null);

    // CSVファイルを読み込み
    useEffect(() => {
        axios
            .get("../university_data.csv") // CSVファイルを取得
            .then((response) => {
                const parsedData = parseCSV(response.data);
                // 41行目までのデータのみをセット
                setData(parsedData.slice(0, 40));
                if (universityName) {
                    const matchedUniversity = parsedData.find(
                        (uni) =>
                            uni.University.toLowerCase() === universityName.toLowerCase()
                    );
                    setSelectedUniversity(matchedUniversity || null);
                }
            })
            .catch((error) => {
                console.error("データの取得中にエラーが発生しました:", error);
            });
    }, [universityName]);

    // CSVデータの解析関数
    const parseCSV = (csvString: string): UniversityData[] => {
        const rows = csvString.split("\n");
        const headers = rows[0].split(",");
        const data: UniversityData[] = rows.slice(1).map((row) => {
            const values = row.split(",");
            return headers.reduce((acc, header, idx) => {
                acc[header.trim() as keyof UniversityData] = values[idx]?.trim();
                return acc;
            }, {} as UniversityData);
        });
        return data;
    };

    // カスタムアイコン生成関数
    const createCustomIcon = (iconUrl: string) => {
        const iconPath = `${iconUrl}`; // 修正: public直下のicon_dataフォルダを参照

        return new L.Icon({
            iconUrl: `/icon_data/${iconUrl}`, // 修正: public直下のicon_dataフォルダを参照
            iconSize: [40, 40], // アイコンサイズ調整
            iconAnchor: [20, 40], // アイコンのアンカー設定
            popupAnchor: [0, -40], // ポップアップの位置調整
        });
    };

    return (
        <div style={{width: "80%", margin: "0 auto"}}>
            <h1>米国の有名CS学科マッピング</h1>
            <p>詳細な情報やウェブページへのリンクは各大学のアイコンをクリックしてください。</p>
            <MapContainer
                center={[39.8283, -98.5795]}
                zoom={4}
                style={{height: "800px", width: "100%", margin: "0 auto"}} // 幅を80%に、中央揃え
                maxBounds={[
                    [85, -180], // 最大緯度・経度（北緯85度、東経180度）
                    [-85, 180], // 最小緯度・経度（南緯85度、西経180度）
                ]}
                minZoom={3}  // 最小ズームレベル（これでこれ以下に縮小できなくなる）
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    noWrap={true} // タイルが繰り返さないように設定
                />
                {data.map((university, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[
                                parseFloat(university.Latitude),
                                parseFloat(university.Longitude),
                            ]}
                            icon={createCustomIcon(university.Icon_Path)}  // アイコンのURLを修正
                            eventHandlers={{
                                click: () => {
                                    setSelectedUniversity(university);
                                },
                            }}
                        >
                            <Popup>
                                <div
                                    style={{
                                        border: "3px solid red",
                                        padding: "10px",
                                        fontSize: "18px",
                                        maxWidth: "300px",
                                    }}
                                >
                                    <b>{university.University}</b>
                                    <br/>
                                    <strong>Rank:</strong> {university.Rank}
                                    <br/>
                                    <strong>Location:</strong> {university.Location}
                                    <br/>
                                    <strong>Info:</strong> {university.Info}
                                </div>
                            </Popup>
                            <Tooltip direction="top" offset={[0, -30]} opacity={1}>
                                {university.University}
                            </Tooltip>
                        </Marker>
                    );
                })}
            </MapContainer>
            {selectedUniversity && (
                <div>
                    <h2>{selectedUniversity.University}</h2>
                    <p>ランキング（US News）: {selectedUniversity.Rank}</p>
                    <p>Location: {selectedUniversity.Location}</p>
                    <p>Info: {selectedUniversity.Info}</p>
                </div>
            )}
        </div>
    );
};

export default UniversityMap;