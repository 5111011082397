import React, { useEffect, useRef, useState } from 'react';
import '../assets/css/global.css';
import '../assets/css/Home.css';
import image1 from '../assets/images/site/main01-pc.jpg';
import image2 from '../assets/images/site/main02-pc.jpg';
import image3 from '../assets/images/site/main03-pc.jpg';
import image4 from '../assets/images/site/main05-pc.jpg';
import SwiperAll from './SwiperAll';


function Home(props: { selectedCategory: string | null;handleCategoryClick: (text: string) => void }) {
    const images = [image1, image2, image3, image4];
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const scrollInterval = 10000; // 自動スクロールの間隔（ミリ秒）

    // 手動スクロール関数
    const handleScroll = (direction: 'left' | 'right') => {
        if (direction === 'right') {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        } else {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        }
    };

    // 自動スクロール
    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (!scrollContainer) return;

        const autoScroll = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        };

        const interval = setInterval(autoScroll, scrollInterval);
        return () => clearInterval(interval); // クリーンアップ
    }, [scrollInterval, images.length]); // images.lengthを依存関係として追加

    // インデックスに基づいてスクロール位置を更新
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = currentIndex * scrollRef.current.clientWidth;
        }
    }, [currentIndex]); // imagesを依存関係として追加

    return (
        <div className="home-page">
            <h1>学ぶなら海外！CS（コンピューターサイエンス）海外留学支援なら、CS-海外留学ラボ</h1>
            <div style={{marginBottom: '20px'}}></div>
            <div className="image-gallery">
                <button onClick={() => handleScroll('left')} className="scroll-button left">◀</button>
                <div className="scroll-container" ref={scrollRef}>
                    {images.map((src, index) => (
                        <img key={index} src={src} alt={`画像${index + 1}`} className="gallery-image"/> // 冗長な単語を削除
                    ))}
                </div>
                <button onClick={() => handleScroll('right')} className="scroll-button right">▶</button>
            </div>
            <div className="indicator-container">
                {images.map((_, index) => (
                    <span key={index} className={`indicator ${currentIndex === index ? 'active' : ''}`}></span>
                ))}
            </div>
            <div className="main-copy">
                <div className="space"></div>
                <p>オンライン対応、世界中どこからでもOK！</p>
                <p>コンピュターサイエンス・大学院留学を目指すあなたを心から応援する</p>
                <p>コンピュターサイエンス・大学院留学支援サービス<br className="sp-only"/>「CS-海外留学ラボ」へようこそ。</p>
                <div className="space"></div>
            </div>

            <div>
                <SwiperAll/>
            </div>

        </div>
    );
}

export default Home;