import '../assets/css/CategoryNav.css'
import '../assets/css/global.css'
import { CategoryList } from "../types";
import { Link } from 'react-router-dom';

function CategoryNav(props: { selectedCategory: string | null; handleCategoryClick: (text: string) => void }) {
  return (
  <nav className="category-nav">
    <ul className="category-buttons">
      {CategoryList.map((category) => (
          <li key={category.categoryId}>
            <Link to ={`/categories/${category.name}`} className={props.selectedCategory === category.name ? 'button selected-category-button' : 'button unselected-category-button'} onClick={() => props.handleCategoryClick(category.name)} >
              {category.name}</Link>
          </li>
          ))}

    </ul>
  </nav>
)
}

export default CategoryNav;

