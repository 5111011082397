import React from 'react';
import '../assets/css/SwiperAll.css';  // 外部CSSが必要な場合は適切なパスを指定
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css'; // SwiperのCSSもインポート

const SwiperAll = () => {
    return (
        <section className="swiper-all" id="linkPackage" style={{ backgroundImage: 'url(https://mbalounge.net/wp-content/themes/mbalounge_theme/images/common/bg-whitecheck.gif)' }}>
            <div className="space-big"></div>
            <h2>
                <div className="title-small">留学準備ステップ特集</div>
            </h2>
            <br />

            {/* コンテンツ */}
            <section>
                <div className="space-small pc-only"></div>
                <h3>
                    <div className="big-copy-center">コンピューターサイエンス留学を目指す全ての方へ。<br />ステップごとに<br className="sp-only" />基本的な事項をまとめています。</div>
                </h3>
                <br />
                <div className="space-small"></div>
                <div className="swiper-custom-parent">
                    <div className="swiper-container slider7 swiper-container-initialized swiper-container-horizontal">
                        <div className="swiper-wrapper">
                            {/* 記事ループはじめ */}
                            <div className="swiper-slide swiper-slide-active" style={{width: '318.788px'}}>
                                {/*<a href="">*/}
                                <div className="blog-img">
                                    <img
                                        width="300"
                                        height="182"
                                        src="https://mbalounge.net/wp-content/uploads/2021/09/step01-300x182.jpg"
                                        alt="【STEP1】MBAって何？という方へ"
                                    />
                                </div>
                                <p className="blog-ttl">【STEP1】MBAって何？という方へ</p>
                                {/*</a>*/}
                            </div>
                            <div className="swiper-slide swiper-slide-active" style={{width: '318.788px'}}>
                                {/*<a href="">*/}
                                <div className="blog-img">
                                    <img
                                        width="300"
                                        height="182"
                                        src="https://mbalounge.net/wp-content/uploads/2021/09/step01-300x182.jpg"
                                        alt="【STEP1】MBAって何？という方へ"
                                    />
                                </div>
                                <p className="blog-ttl">【STEP1】MBAって何？という方へ</p>
                                {/*</a>*/}
                            </div>
                            <div className="swiper-slide swiper-slide-active" style={{width: '318.788px'}}>
                                {/*<a href="">*/}
                                <div className="blog-img">
                                    <img
                                        width="300"
                                        height="182"
                                        src="https://mbalounge.net/wp-content/uploads/2021/09/step01-300x182.jpg"
                                        alt="【STEP1】MBAって何？という方へ"
                                    />
                                </div>
                                <p className="blog-ttl">【STEP1】MBAって何？という方へ</p>
                                {/*</a>*/}
                            </div>
                            <div className="swiper-slide swiper-slide-active" style={{width: '318.788px'}}>
                                {/*<a href="">*/}
                                <div className="blog-img">
                                    <img
                                        width="300"
                                        height="182"
                                        src="https://mbalounge.net/wp-content/uploads/2021/09/step01-300x182.jpg"
                                        alt="【STEP1】MBAって何？という方へ"
                                    />
                                </div>
                                <p className="blog-ttl">【STEP1】MBAって何？という方へ</p>
                                {/*</a>*/}
                            </div>
                            {/* 記事ループEND */}

                            {/* 他のステップのスライドも同様に繰り返し */}
                            {/* ここに他のステップリンクを追加 */}
                        </div>
                    </div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>
            </section>
            {/* コンテンツEND */}
            <div className="space-big"></div>
        </section>
    );
}

export default SwiperAll;